import React from "react"
import PropTypes from "prop-types"

import FormSubscribe from "src/components/FormSubscribe"
import Layout from "src/components/Layout"
import i18n, { changeLangIfNeeded } from "src/i18n"
import s from "../templates/form.module.scss"

const Page = () => {
  return (
    <Layout className={s.main}>
      <FormSubscribe cursinho={{ name: "Cursinho Emancipa", slug: "geral" }} />
    </Layout>
  )
}

Page.propTypes = {
  pageContext: PropTypes.object,
}

export default Page
